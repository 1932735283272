import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import axios from "axios";
// import App from './App';
import App from "./sidebarpro/App";
import { GetItem } from "./common/util";

//axios.defaults.baseURL = "http://10.17.45.3/ifrs_api_capital";
axios.defaults.baseURL ="https://dev-api-ifrs.capitallife.co.id/ifrs_api_capital_dev";

axios.defaults.headers.common["Authorization"] = "Bearer " + GetItem("token");
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
