import React, { Component, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { BASE_URL } from "../../../common/constantVariable";
import { GetItem, ValidateData } from "../../../common/util";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  REGISTER_SETUP_MAP_PRODUK,
  REGISTER_SETUP_MAP_PRODUK_UPLOAD,
} from "../../../common/constantVariable";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  form: {
    textAlign: "left",
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "10px auto 10px auto",
  },
  textField: {
    margin: "10px auto 10px auto",
    width: 250,
  },
  textField2: {
    margin: "10px auto 10px auto",
    width: 760,
  },
  button: {
    marginTop: 10,
  },

  m10: {
    marginTop: 10,
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
};
const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

class ProsesUploadApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      IFRS_MODEL: GetItem("IFRS_MODEL"),
      PRODUCT_CODE: GetItem("PRODUCT_CODE"),
      user_id: GetItem("user_id"),
      upload_dokumen: "",
      kd_produk: GetItem("PRODUCT_CODE"),
      listMap: [],
      listKeyBenefitDesc: [],
      listKdFormula: [],
      product_name: "",
      cohort_prefix: "",
      ifrs_model: GetItem("IFRS_MODEL"),
      product_risk: "",
      product_category: "",
      death_benefit: "",
      maturity_benefit: "",
      lapse_benefit: "",
      endowment_benefit: "",
      beban_penalty_benefit: "",
      beban_penalty_value: "",
      rider_benefit: "",
      valid: false,
      messageError: "",
      message: "",
      showForm: false,
      openSnackError: false,
      Backdrop: false,
      openSnack: false,
      disabled_option: false,
      beban_penalty_valueReadOnly: false,
      btnWording: "Simpan",
      btnWording1: "Upload dan Simpan",

      b1_key_benefit_1: "",
      b1_key_benefit_2: "",
      b1_key_benefit_3: "",
      b1_basis: "",
      b1_jenis_manfaat: "",
      b1_logic: false,
      b1_max_benefit: "",
      b1_af_max_benefit: "",
      b1_age_basis: "",
      b1_mth_0_benefit: "",

      b2_key_benefit_1: "",
      b2_key_benefit_2: "",
      b2_key_benefit_3: "",
      b2_basis: "",
      b2_jenis_manfaat: "",
      b2_logic: "",
      b2_max_benefit: "",
      b2_af_max_benefit: "",
      b2_age_basis: "",
      b2_mth_0_benefit: "",

      b3_key_benefit_1: "",
      b3_key_benefit_2: "",
      b3_key_benefit_3: "",
      b3_basis: "",
      b3_jenis_manfaat: "",
      b3_logic: "",
      b3_max_benefit: "",
      b3_af_max_benefit: "",
      b3_age_basis: "",
      b3_mth_0_benefit: "",

      b4_key_benefit_1: "",
      b4_key_benefit_2: "",
      b4_key_benefit_3: "",
      b4_basis: "",
      b4_jenis_manfaat: "",
      b4_logic: "",
      b4_max_benefit: "",
      b4_af_max_benefit: "",
      b4_age_basis: "",
      b4_mth_0_benefit: "",

      b5_key_benefit_1: "",
      b5_key_benefit_2: "",
      b5_key_benefit_3: "",
      b5_basis: "",
      b5_jenis_manfaat: "",
      b5_logic: "",
      b5_max_benefit: "",
      b5_af_max_benefit: "",
      b5_age_basis: "",
      b5_mth_0_benefit: "",

      b6_key_benefit_1: "",
      b6_key_benefit_2: "",
      b6_key_benefit_3: "",
      b6_basis: "",
      b6_jenis_manfaat: "",
      b6_logic: "",
      b6_max_benefit: "",
      b6_af_max_benefit: "",
      b6_age_basis: "",
      b6_mth_0_benefit: "",

      b7_key_benefit_1: "",
      b7_key_benefit_2: "",
      b7_key_benefit_3: "",
      b7_basis: "",
      b7_jenis_manfaat: "",
      b7_logic: "",
      b7_max_benefit: "",
      b7_af_max_benefit: "",
      b7_age_basis: "",
      b7_mth_0_benefit: "",
      tipe_up: "",
      tipe_up_show: false,

      visibileBenefit1: false,
      visibileBenefit2: false,
      visibileBenefit3: false,
      visibileBenefit4: false,
      visibileBenefit5: false,
      visibileBenefit6: false,
      visibileBenefit7: false,
      kd_formula: "",
    };
  }

  componentDidMount() {
    this.setState({ openBackdrop: true });
    axios
      .get(BASE_URL + "setupparameter/list_map_desc")
      .then((res) => {
        this.setState({
          listMap: res.data.data.RISK,
          listKeyBenefitDesc: res.data.data.KEY_BENEFIT,
          listKdFormula: res.data.data.KD_FORMULA,
        });
      })
      .catch((err) => {
        this.setState({
          listMap: [],
        });
      });

    axios
      .get(
        BASE_URL +
          "setupparameter/map_product_detail/" +
          GetItem("PRODUCT_CODE") +
          "/" +
          GetItem("IFRS_MODEL")
      )
      .then((res) => {
        let beban_penalty = "";
        let tipe_up_show = false;

        if (res.data.data.penalty.PARAM_VALUE > 0) {
          beban_penalty = 1;
        } else {
          beban_penalty = 0;
        }

        if (res.data.data.productDetail.KD_TIPE_UP_AJK !== null) {
          tipe_up_show = true;
        }

        if (
          res.data.data.benefit1 != null &&
          res.data.data.benefit1.MAP_VALUE === "1"
        ) {
          let dt_visibileBenefit1 = false;
          let dt_b1_logic = false;
          dt_visibileBenefit1 = true;
          dt_b1_logic = true;

          this.setState({
            b1_logic: dt_b1_logic,
            visibileBenefit1: dt_visibileBenefit1,
            b1_basis: res.data.data.benefit1.MAP_BASIS,
            b1_jenis_manfaat: res.data.data.benefit1.MAP_DESC,
            b1_age_basis: res.data.data.benefit1.AGE_BASIS,
            b1_key_benefit_1: res.data.data.benefit1.KEY_BENEFIT_1,
            b1_key_benefit_2: res.data.data.benefit1.KEY_BENEFIT_2,
            b1_key_benefit_3: res.data.data.benefit1.KEY_BENEFIT_3,
            b1_mth_0_benefit: res.data.data.benefit1.MTH_0_BENEFIT,
            b1_af_max_benefit: res.data.data.productDetail.AF_B1_MAX_BENEFIT,
            b1_max_benefit: res.data.data.productDetail.B1_MAX_BENEFIT,
          });
        }
        if (
          res.data.data.benefit2 != null &&
          res.data.data.benefit2.MAP_VALUE === "1"
        ) {
          let dt_visibileBenefit2 = true;
          let dt_b2_logic = true;

          this.setState({
            b2_logic: dt_b2_logic,
            visibileBenefit2: dt_visibileBenefit2,
            b2_basis: res.data.data.benefit2.MAP_BASIS,
            b2_jenis_manfaat: res.data.data.benefit2.MAP_DESC,
            b2_age_basis: res.data.data.benefit2.AGE_BASIS,
            b2_key_benefit_1: res.data.data.benefit2.KEY_BENEFIT_1,
            b2_key_benefit_2: res.data.data.benefit2.KEY_BENEFIT_2,
            b2_key_benefit_3: res.data.data.benefit2.KEY_BENEFIT_3,
            b2_mth_0_benefit: res.data.data.benefit2.MTH_0_BENEFIT,
            b2_af_max_benefit: res.data.data.productDetail.AF_B2_MAX_BENEFIT,
            b2_max_benefit: res.data.data.productDetail.B2_MAX_BENEFIT,
          });
        }

        if (
          res.data.data.benefit3 != null &&
          res.data.data.benefit3.MAP_VALUE === "1"
        ) {
          let dt_visibileBenefit3 = true;
          let dt_b3_logic = true;

          this.setState({
            b3_logic: dt_b3_logic,
            visibileBenefit3: dt_visibileBenefit3,
            b3_basis: res.data.data.benefit3.MAP_BASIS,
            b3_jenis_manfaat: res.data.data.benefit3.MAP_DESC,
            b3_age_basis: res.data.data.benefit3.AGE_BASIS,
            b3_key_benefit_1: res.data.data.benefit3.KEY_BENEFIT_1,
            b3_key_benefit_2: res.data.data.benefit3.KEY_BENEFIT_2,
            b3_key_benefit_3: res.data.data.benefit3.KEY_BENEFIT_3,
            b3_mth_0_benefit: res.data.data.benefit3.MTH_0_BENEFIT,
            b3_af_max_benefit: res.data.data.productDetail.AF_B3_MAX_BENEFIT,
            b3_max_benefit: res.data.data.productDetail.B3_MAX_BENEFIT,
          });
        }

        if (
          res.data.data.benefit4 != null &&
          res.data.data.benefit4.MAP_VALUE === "1"
        ) {
          let dt_visibileBenefit4 = true;
          let dt_b4_logic = true;

          this.setState({
            b4_logic: dt_b4_logic,
            visibileBenefit4: dt_visibileBenefit4,
            b4_basis: res.data.data.benefit4.MAP_BASIS,
            b4_jenis_manfaat: res.data.data.benefit4.MAP_DESC,
            b4_age_basis: res.data.data.benefit4.AGE_BASIS,
            b4_key_benefit_1: res.data.data.benefit4.KEY_BENEFIT_1,
            b4_key_benefit_2: res.data.data.benefit4.KEY_BENEFIT_2,
            b4_key_benefit_3: res.data.data.benefit4.KEY_BENEFIT_3,
            b4_mth_0_benefit: res.data.data.benefit4.MTH_0_BENEFIT,
            b4_af_max_benefit: res.data.data.productDetail.AF_B4_MAX_BENEFIT,
            b4_max_benefit: res.data.data.productDetail.B4_MAX_BENEFIT,
          });
        }

        if (
          res.data.data.benefit5 != null &&
          res.data.data.benefit5.MAP_VALUE === "1"
        ) {
          let dt_visibileBenefit5 = true;
          let dt_b5_logic = true;

          this.setState({
            b5_logic: dt_b5_logic,
            visibileBenefit5: dt_visibileBenefit5,
            b5_basis: res.data.data.benefit5.MAP_BASIS,
            b5_jenis_manfaat: res.data.data.benefit5.MAP_DESC,
            b5_age_basis: res.data.data.benefit5.AGE_BASIS,
            b5_key_benefit_1: res.data.data.benefit5.KEY_BENEFIT_1,
            b5_key_benefit_2: res.data.data.benefit5.KEY_BENEFIT_2,
            b5_key_benefit_3: res.data.data.benefit5.KEY_BENEFIT_3,
            b5_mth_0_benefit: res.data.data.benefit5.MTH_0_BENEFIT,
            b5_af_max_benefit: res.data.data.productDetail.AF_B5_MAX_BENEFIT,
            b5_max_benefit: res.data.data.productDetail.B5_MAX_BENEFIT,
          });
        }

        if (
          res.data.data.benefit6 != null &&
          res.data.data.benefit6.MAP_VALUE === "1"
        ) {
          let dt_visibileBenefit6 = true;
          let dt_b6_logic = true;

          this.setState({
            b6_logic: dt_b6_logic,
            visibileBenefit6: dt_visibileBenefit6,
            b6_basis: res.data.data.benefit6.MAP_BASIS,
            b6_jenis_manfaat: res.data.data.benefit6.MAP_DESC,
            b6_age_basis: res.data.data.benefit6.AGE_BASIS,
            b6_key_benefit_1: res.data.data.benefit6.KEY_BENEFIT_1,
            b6_key_benefit_2: res.data.data.benefit6.KEY_BENEFIT_2,
            b6_key_benefit_3: res.data.data.benefit6.KEY_BENEFIT_3,
            b6_mth_0_benefit: res.data.data.benefit6.MTH_0_BENEFIT,
            b6_af_max_benefit: res.data.data.productDetail.AF_B6_MAX_BENEFIT,
            b6_max_benefit: res.data.data.productDetail.B6_MAX_BENEFIT,
          });
        }

        if (
          res.data.data.benefit7 != null &&
          res.data.data.benefit7.MAP_VALUE === "1"
        ) {
          let dt_visibileBenefit7 = true;
          let dt_b7_logic = true;

          this.setState({
            b7_logic: dt_b7_logic,
            visibileBenefit7: dt_visibileBenefit7,
            b7_basis: res.data.data.benefit7.MAP_BASIS,
            b7_jenis_manfaat: res.data.data.benefit7.MAP_DESC,
            b7_age_basis: res.data.data.benefit7.AGE_BASIS,
            b7_key_benefit_1: res.data.data.benefit7.KEY_BENEFIT_1,
            b7_key_benefit_2: res.data.data.benefit7.KEY_BENEFIT_2,
            b7_key_benefit_3: res.data.data.benefit7.KEY_BENEFIT_3,
            b7_mth_0_benefit: res.data.data.benefit7.MTH_0_BENEFIT,
            b7_af_max_benefit: res.data.data.productDetail.AF_B7_MAX_BENEFIT,
            b7_max_benefit: res.data.data.productDetail.B7_MAX_BENEFIT,
          });
        }

        this.setState({
          product_name: res.data.data.productDetail.PRODUCT_NAME,
          cohort_prefix: res.data.data.productDetail.COHORT_PREFIX,
          product_risk: res.data.data.productDetail.PRODUCT_RISK,
          product_category: res.data.data.productDetail.PRODUCT_KATEGORY,
          kd_formula: res.data.data.productDetail.KD_FORMULA,
          tipe_up: res.data.data.productDetail.KD_TIPE_UP_AJK,
          tipe_up_show: tipe_up_show,
          beban_penalty_benefit: beban_penalty,
          beban_penalty_value: res.data.data.penalty.PARAM_VALUE,
        });

        this.setState({ openBackdrop: false });
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        // this.setState({
        //   listMap: [],
        // });
      });
  }

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    if (e.target.name === "kd_formula" && e.target.value === "F1") {
      this.setState({ tipe_up_show: true });
    } else if (e.target.name === "kd_formula" && e.target.value != "F1") {
      this.setState({ tipe_up_show: false });
    }

    if (e.target.name === "beban_penalty_benefit" && e.target.value === "1") {
      this.setState({
        beban_penalty_valueReadOnly: false,
        beban_penalty_value: "",
      });
    } else if (
      e.target.name === "beban_penalty_benefit" &&
      e.target.value === "0"
    ) {
      this.setState({
        beban_penalty_valueReadOnly: true,
        beban_penalty_value: "0",
      });
    }

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });

    if (e.target.name === "b1_logic" && e.target.checked) {
      this.setState({ visibileBenefit1: true, b1_logic: true });
    } else if (e.target.name === "b1_logic" && !e.target.checked) {
      this.setState({
        visibileBenefit1: false,
        b1_basis: "",
        b1_logic: false,
        b1_jenis_manfaat: "",
        b1_age_basis: "",
        b1_key_benefit_1: "",
        b1_key_benefit_2: "",
        b1_key_benefit_3: "",
        b1_mth_0_benefit: "",
        b1_af_max_benefit: "",
        b1_max_benefit: "",
      });
    }
    if (e.target.name === "b2_logic" && e.target.checked) {
      this.setState({ visibileBenefit2: true, b2_logic: true });
    } else if (e.target.name === "b2_logic" && !e.target.checked) {
      this.setState({
        visibileBenefit2: false,
        b2_basis: "",
        b2_logic: false,
        b2_jenis_manfaat: "",
        b2_age_basis: "",
        b2_key_benefit_1: "",
        b2_key_benefit_2: "",
        b2_key_benefit_3: "",
        b2_mth_0_benefit: "",
        b2_af_max_benefit: "",
        b2_max_benefit: "",
      });
    }
    if (e.target.name === "b3_logic" && e.target.checked) {
      this.setState({ visibileBenefit3: true, b3_logic: true });
    } else if (e.target.name === "b3_logic" && !e.target.checked) {
      this.setState({
        visibileBenefit3: false,
        b3_basis: "",
        b3_logic: false,
        b3_jenis_manfaat: "",
        b3_age_basis: "",
        b3_key_benefit_1: "",
        b3_key_benefit_2: "",
        b3_key_benefit_3: "",
        b3_mth_0_benefit: "",
        b3_af_max_benefit: "",
        b3_max_benefit: "",
      });
    }
    if (e.target.name === "b4_logic" && e.target.checked) {
      this.setState({ visibileBenefit4: true, b4_logic: true });
    } else if (e.target.name === "b4_logic" && !e.target.checked) {
      this.setState({
        visibileBenefit4: false,
        b4_basis: "",
        b4_logic: false,
        b4_jenis_manfaat: "",
        b4_age_basis: "",
        b4_key_benefit_1: "",
        b4_key_benefit_2: "",
        b4_key_benefit_3: "",
        b4_mth_0_benefit: "",
        b4_af_max_benefit: "",
        b4_max_benefit: "",
      });
    }
    if (e.target.name === "b5_logic" && e.target.checked) {
      this.setState({ visibileBenefit5: true, b5_logic: true });
    } else if (e.target.name === "b5_logic" && !e.target.checked) {
      this.setState({
        visibileBenefit5: false,
        b5_basis: "",
        b5_logic: false,
        b5_jenis_manfaat: "",
        b5_age_basis: "",
        b5_key_benefit_1: "",
        b5_key_benefit_2: "",
        b5_key_benefit_3: "",
        b5_mth_0_benefit: "",
        b5_af_max_benefit: "",
        b5_max_benefit: "",
      });
    }
    if (e.target.name === "b6_logic" && e.target.checked) {
      this.setState({ visibileBenefit6: true, b6_logic: true });
    } else if (e.target.name === "b6_logic" && !e.target.checked) {
      this.setState({
        visibileBenefit6: false,
        b6_basis: "",
        b6_logic: false,
        b6_jenis_manfaat: "",
        b6_age_basis: "",
        b6_key_benefit_1: "",
        b6_key_benefit_2: "",
        b6_key_benefit_3: "",
        b6_mth_0_benefit: "",
        b6_af_max_benefit: "",
        b6_max_benefit: "",
      });
    }
    if (e.target.name === "b7_logic" && e.target.checked) {
      this.setState({ visibileBenefit7: true, b7_logic: true });
    } else if (e.target.name === "b7_logic" && !e.target.checked) {
      this.setState({
        visibileBenefit7: false,
        b7_basis: "",
        b7_logic: false,
        b7_jenis_manfaat: "",
        b7_age_basis: "",
        b7_key_benefit_1: "",
        b7_key_benefit_2: "",
        b7_key_benefit_3: "",
        b7_mth_0_benefit: "",
        b7_af_max_benefit: "",
        b7_max_benefit: "",
      });
    }
  };

  handleSubmit = () => {
    const e = document.getElementById("form1");

    const valid = ValidateData(e.elements);

    if (
      this.state.kd_produk === "" ||
      this.state.product_name === "" ||
      this.state.product_category === "" ||
      this.state.product_risk === "" ||
      this.state.ifrs_model === "" ||
      this.state.beban_penalty_benefit === "" ||
      this.state.cohort_prefix === ""
    ) {
      this.setState({
        message: "Data tidak lengkap!",
        openSnackError: true,
      });
      return false;
    }

    this.setState({ openBackdrop: true });
    axios
      .put(
        "setupparameter/map_product/" +
          this.state.PRODUCT_CODE +
          "/" +
          this.state.IFRS_MODEL,
        this.state
      )
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message !== "success") {
          this.setState({
            message: res.data.data,
            btnWording: "Simpan",
            openSnackError: true,
          });
        } else {
          this.setState({
            message: res.data.data,
            btnWording: "Simpan",
            openSnack: true,

            // errors: "",
            // message: "",
            // openSnackError: true,
            // btnWording: "Simpan",
          });
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          errors: err.response.data.data,
          message: err.response.data.data,
          openSnackError: true,
          btnWording: "Simpan",
          loading: false,
        });
      });
  };

  handleClose = (event, reason) => {
    this.setState({ openSnack: false });
    if (reason === "timeout") {
      window.history.back(-1);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Box>
        <Paper
          variant="outlined"
          sx={{
            p: 1,
            margin: "auto",
            flexGrow: 1,
          }}
        >
          <Grid container className={classes.form}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.openBackdrop}
              onClick={this.handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
              open={this.state.openSnack}
              autoHideDuration={1000}
              onClose={(event, reason) => this.handleClose(event, reason)}
            >
              <Alert
                severity="success"
                sx={{ width: "100%" }}
                variant="filled"
                onClose={() => {
                  this.setState({ openSnack: false });
                  window.history.back(-1);
                }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
            <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
              <Alert
                severity="error"
                sx={{ width: "100%" }}
                variant="filled"
                onClose={() => {
                  this.setState({ openSnackError: false });
                }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
            <Grid item sm>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                <br></br> Setup Product
              </Typography>
              <form id="form1" name="form1" noValidate>
                {" "}
                <br></br>
                <FormControl variant="outlined" className={classes.textField}>
                  <TextField
                    id="user_id"
                    name="kd_produk"
                    type="text"
                    label="Product Code"
                    size="small"
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.kd_produk}
                    onChange={this.handleChange}
                    required
                  />
                </FormControl>{" "}
                <FormControl variant="outlined" className={classes.textField}>
                  <TextField
                    id="user_id"
                    name="product_name"
                    type="text"
                    label="Product Name"
                    size="small"
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.product_name}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{ marginTop: 10 }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    IFRS MODEL
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.ifrs_model}
                    onChange={this.handleChange}
                    size="small"
                    name="ifrs_model"
                    label="IFRS MODEL"
                  >
                    <MenuItem value="GMM">GMM</MenuItem>
                    <MenuItem value="PAA">PAA</MenuItem>
                    <MenuItem value="VFA">VFA</MenuItem>
                    <MenuItem value="LIC">LIC</MenuItem>
                    <MenuItem value="REASGMM">REAS GMM</MenuItem>
                    <MenuItem value="REASPAA">REAS PAA</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{ marginTop: 10 }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Product Risk
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.product_risk}
                    onChange={this.handleChange}
                    size="small"
                    name="product_risk"
                    label="Product Risk"
                  >
                    {this.state.listMap.map((listM, idx) => (
                      <MenuItem value={listM.PRODUCT_RISK}>
                        {listM.PRODUCT_RISK}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl variant="outlined" className={classes.textField}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Product Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.product_category}
                    onChange={this.handleChange}
                    size="small"
                    name="product_category"
                    label="Product Category"
                  >
                    <MenuItem value="GROUP">GROUP</MenuItem>
                    <MenuItem value="INDIVIDU">INDIVIDU</MenuItem>
                    <MenuItem value="GUARANTED (INDIVIDU)">
                      GUARANTED (INDIVIDU)
                    </MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl variant="outlined" className={classes.textField}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Beban Penalty Benefit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.beban_penalty_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="beban_penalty_benefit"
                    label="Beban Penalty Benefit"
                  >
                    <MenuItem value="0">TIDAK</MenuItem>
                    <MenuItem value="1">YA</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl variant="outlined" className={classes.textField}>
                  <TextField
                    id="user_id"
                    name="beban_penalty_value"
                    type="number"
                    label="Beban Penalty Value"
                    size="small"
                    variant="outlined"
                    disabled={this.state.beban_penalty_valueReadOnly}
                    value={this.state.beban_penalty_value}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl variant="outlined" className={classes.textField}>
                  <TextField
                    id="user_id"
                    name="cohort_prefix"
                    type="text"
                    label="Cohort Prefix"
                    size="small"
                    variant="outlined"
                    value={this.state.cohort_prefix}
                    onChange={this.handleChange}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>{" "}
                <br></br>
                <br></br>
                <FormControl variant="outlined" className={classes.textField}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Kode Formula
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.kd_formula}
                    onChange={this.handleChange}
                    size="small"
                    name="kd_formula"
                    label="Kode Formula"
                  >
                    {this.state.listKdFormula.map((listM, idx) => (
                      <MenuItem value={listM.KD_FORMULA}>
                        {listM.KD_FORMULA} - {listM.DESKRIPSI}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.tipe_up_show ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Tipe UP Produk
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.tipe_up}
                    onChange={this.handleChange}
                    size="small"
                    name="tipe_up"
                    label="Tipe UP Produk"
                  >
                    <MenuItem value="1">UP Tetap</MenuItem>
                    <MenuItem value="2">UP Menurun</MenuItem>
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="b1_logic"
                      value={this.state.b1_logic}
                      checked={!!this.state.b1_logic}
                      onChange={this.handleChange}
                    />
                  }
                  label="MAPPING BENEFIT 1"
                />
                <br
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <TextField
                    id="b1_jenis_manfaat"
                    name="b1_jenis_manfaat"
                    type="text"
                    label="Jenis Manfaat"
                    size="small"
                    variant="outlined"
                    value={this.state.b1_jenis_manfaat}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b1_basis"
                    value={this.state.b1_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b1_basis"
                    label="Basis"
                  >
                    <MenuItem value="1">Per 1000 UP</MenuItem>
                    <MenuItem value="2">Per 100 Premi</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Age Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b1_age_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b1_age_basis"
                    label="Age Basis"
                  >
                    <MenuItem value="POL_TERM_M">Monthly per Policy</MenuItem>
                    <MenuItem value="POL_TERM_AVG">Monthly Average</MenuItem>
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 1
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b1_key_benefit_1"
                    value={this.state.b1_key_benefit_1}
                    onChange={this.handleChange}
                    size="small"
                    name="b1_key_benefit_1"
                    label="Key Benefit 1"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 2
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b1_key_benefit_2"
                    value={this.state.b1_key_benefit_2}
                    onChange={this.handleChange}
                    size="small"
                    name="b1_key_benefit_2"
                    label="Key Benefit 2"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 3
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b1_key_benefit_3"
                    value={this.state.b1_key_benefit_3}
                    onChange={this.handleChange}
                    size="small"
                    name="b1_key_benefit_3"
                    label="Key Benefit 3"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir Before Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b1_max_benefit"
                    value={this.state.b1_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b1_max_benefit"
                    label="Nilai Bulan Terakhir Before Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir After Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b1_af_max_benefit"
                    value={this.state.b1_af_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b1_af_max_benefit"
                    label="Nilai Bulan Terakhir After Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Month 0 Benefit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b1_mth_0_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b1_mth_0_benefit"
                    label="Month 0 Benefit"
                  >
                    <MenuItem value="0">Month Benefit dimulai bulan 0</MenuItem>
                    <MenuItem value="1">Month Benefit dimulai bulan 1</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                ></br>
                <Divider
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="b2_logic"
                      value={this.state.b2_logic}
                      checked={!!this.state.b2_logic}
                      onChange={this.handleChange}
                    />
                  }
                  label="MAPPING BENEFIT 2"
                />
                <br
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <TextField
                    id="b2_jenis_manfaat"
                    name="b2_jenis_manfaat"
                    type="text"
                    label="Jenis Manfaat"
                    size="small"
                    variant="outlined"
                    value={this.state.b2_jenis_manfaat}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b2_basis"
                    value={this.state.b2_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b2_basis"
                    label="Basis"
                  >
                    <MenuItem value="1">Per 1000 UP</MenuItem>
                    <MenuItem value="2">Per 100 Premi</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Age Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b2_age_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b2_age_basis"
                    label="Age Basis"
                  >
                    <MenuItem value="POL_TERM_M">Monthly per Policy</MenuItem>
                    <MenuItem value="POL_TERM_AVG">Monthly Average</MenuItem>
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 1
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b2_key_benefit_1"
                    value={this.state.b2_key_benefit_1}
                    onChange={this.handleChange}
                    size="small"
                    name="b2_key_benefit_1"
                    label="Key Benefit 1"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 2
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b2_key_benefit_2"
                    value={this.state.b2_key_benefit_2}
                    onChange={this.handleChange}
                    size="small"
                    name="b2_key_benefit_2"
                    label="Key Benefit 2"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 3
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b2_key_benefit_3"
                    value={this.state.b2_key_benefit_3}
                    onChange={this.handleChange}
                    size="small"
                    name="b2_key_benefit_3"
                    label="Key Benefit 3"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir Before Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b2_max_benefit"
                    value={this.state.b2_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b2_max_benefit"
                    label="Nilai Bulan Terakhir Before Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir After Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b2_af_max_benefit"
                    value={this.state.b2_af_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b2_af_max_benefit"
                    label="Nilai Bulan Terakhir After Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Month 0 Benefit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b2_mth_0_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b2_mth_0_benefit"
                    label="Month 0 Benefit"
                  >
                    <MenuItem value="0">Month Benefit dimulai bulan 0</MenuItem>
                    <MenuItem value="1">Month Benefit dimulai bulan 1</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                ></br>
                <Divider
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="b3_logic"
                      value={this.state.b3_logic}
                      checked={!!this.state.b3_logic}
                      onChange={this.handleChange}
                    />
                  }
                  label="MAPPING BENEFIT 3"
                />
                <br
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <TextField
                    id="b3_jenis_manfaat"
                    name="b3_jenis_manfaat"
                    type="text"
                    label="Jenis Manfaat"
                    size="small"
                    variant="outlined"
                    value={this.state.b3_jenis_manfaat}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b3_basis"
                    value={this.state.b3_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b3_basis"
                    label="Basis"
                  >
                    <MenuItem value="1">Per 1000 UP</MenuItem>
                    <MenuItem value="2">Per 100 Premi</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Age Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b3_age_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b3_age_basis"
                    label="Age Basis"
                  >
                    <MenuItem value="POL_TERM_M">Monthly per Policy</MenuItem>
                    <MenuItem value="POL_TERM_AVG">Monthly Average</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 1
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b3_key_benefit_1"
                    value={this.state.b3_key_benefit_1}
                    onChange={this.handleChange}
                    size="small"
                    name="b3_key_benefit_1"
                    label="Key Benefit 1"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 2
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b3_key_benefit_2"
                    value={this.state.b3_key_benefit_2}
                    onChange={this.handleChange}
                    size="small"
                    name="b3_key_benefit_2"
                    label="Key Benefit 2"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 3
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b3_key_benefit_3"
                    value={this.state.b3_key_benefit_3}
                    onChange={this.handleChange}
                    size="small"
                    name="b3_key_benefit_3"
                    label="Key Benefit 3"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir Before Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b3_max_benefit"
                    value={this.state.b3_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b3_max_benefit"
                    label="Nilai Bulan Terakhir Before Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir After Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b3_af_max_benefit"
                    value={this.state.b3_af_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b3_af_max_benefit"
                    label="Nilai Bulan Terakhir After Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Month 0 Benefit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b3_mth_0_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b3_mth_0_benefit"
                    label="Month 0 Benefit"
                  >
                    <MenuItem value="0">Month Benefit dimulai bulan 0</MenuItem>
                    <MenuItem value="1">Month Benefit dimulai bulan 1</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                ></br>
                <Divider
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="b4_logic"
                      value={this.state.b4_logic}
                      checked={!!this.state.b4_logic}
                      onChange={this.handleChange}
                    />
                  }
                  label="MAPPING BENEFIT 4"
                />
                <br
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <TextField
                    id="b4_jenis_manfaat"
                    name="b4_jenis_manfaat"
                    type="text"
                    label="Jenis Manfaat"
                    size="small"
                    variant="outlined"
                    value={this.state.b4_jenis_manfaat}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b4_basis"
                    value={this.state.b4_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b4_basis"
                    label="Basis"
                  >
                    <MenuItem value="1">Per 1000 UP</MenuItem>
                    <MenuItem value="2">Per 100 Premi</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Age Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b4_age_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b4_age_basis"
                    label="Age Basis"
                  >
                    <MenuItem value="POL_TERM_M">Monthly per Policy</MenuItem>
                    <MenuItem value="POL_TERM_AVG">Monthly Average</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 1
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b4_key_benefit_1"
                    value={this.state.b4_key_benefit_1}
                    onChange={this.handleChange}
                    size="small"
                    name="b4_key_benefit_1"
                    label="Key Benefit 1"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 2
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b4_key_benefit_2"
                    value={this.state.b4_key_benefit_2}
                    onChange={this.handleChange}
                    size="small"
                    name="b4_key_benefit_2"
                    label="Key Benefit 2"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 3
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b4_key_benefit_3"
                    value={this.state.b4_key_benefit_3}
                    onChange={this.handleChange}
                    size="small"
                    name="b4_key_benefit_3"
                    label="Key Benefit 3"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir Before Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b4_max_benefit"
                    value={this.state.b4_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b4_max_benefit"
                    label="Nilai Bulan Terakhir Before Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir After Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b4_af_max_benefit"
                    value={this.state.b4_af_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b4_af_max_benefit"
                    label="Nilai Bulan Terakhir After Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Month 0 Benefit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b4_mth_0_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b4_mth_0_benefit"
                    label="Month 0 Benefit"
                  >
                    <MenuItem value="0">Month Benefit dimulai bulan 0</MenuItem>
                    <MenuItem value="1">Month Benefit dimulai bulan 1</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                ></br>
                <Divider
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="b5_logic"
                      value={this.state.b5_logic}
                      checked={!!this.state.b5_logic}
                      onChange={this.handleChange}
                    />
                  }
                  label="MAPPING BENEFIT 5"
                />
                <br
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <TextField
                    id="b5_jenis_manfaat"
                    name="b5_jenis_manfaat"
                    type="text"
                    label="Jenis Manfaat"
                    size="small"
                    variant="outlined"
                    value={this.state.b5_jenis_manfaat}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b5_basis"
                    value={this.state.b5_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b5_basis"
                    label="Basis"
                  >
                    <MenuItem value="1">Per 1000 UP</MenuItem>
                    <MenuItem value="2">Per 100 Premi</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Age Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b5_age_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b5_age_basis"
                    label="Age Basis"
                  >
                    <MenuItem value="POL_TERM_M">Monthly per Policy</MenuItem>
                    <MenuItem value="POL_TERM_AVG">Monthly Average</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 1
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b5_key_benefit_1"
                    value={this.state.b5_key_benefit_1}
                    onChange={this.handleChange}
                    size="small"
                    name="b5_key_benefit_1"
                    label="Key Benefit 1"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 2
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b5_key_benefit_2"
                    value={this.state.b5_key_benefit_2}
                    onChange={this.handleChange}
                    size="small"
                    name="b5_key_benefit_2"
                    label="Key Benefit 2"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 3
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b5_key_benefit_3"
                    value={this.state.b5_key_benefit_3}
                    onChange={this.handleChange}
                    size="small"
                    name="b5_key_benefit_3"
                    label="Key Benefit 3"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir Before Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b5_max_benefit"
                    value={this.state.b5_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b5_max_benefit"
                    label="Nilai Bulan Terakhir Before Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir After Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b5_af_max_benefit"
                    value={this.state.b5_af_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b5_af_max_benefit"
                    label="Nilai Bulan Terakhir After Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Month 0 Benefit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b5_mth_0_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b5_mth_0_benefit"
                    label="Month 0 Benefit"
                  >
                    <MenuItem value="0">Month Benefit dimulai bulan 0</MenuItem>
                    <MenuItem value="1">Month Benefit dimulai bulan 1</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                ></br>
                <Divider
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="b6_logic"
                      value={this.state.b6_logic}
                      checked={!!this.state.b6_logic}
                      onChange={this.handleChange}
                    />
                  }
                  label="MAPPING BENEFIT 6"
                />
                <br
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <TextField
                    id="b6_jenis_manfaat"
                    name="b6_jenis_manfaat"
                    type="text"
                    label="Jenis Manfaat"
                    size="small"
                    variant="outlined"
                    value={this.state.b6_jenis_manfaat}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b6_basis"
                    value={this.state.b6_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b6_basis"
                    label="Basis"
                  >
                    <MenuItem value="1">Per 1000 UP</MenuItem>
                    <MenuItem value="2">Per 100 Premi</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Age Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b6_age_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b6_age_basis"
                    label="Age Basis"
                  >
                    <MenuItem value="POL_TERM_M">Monthly per Policy</MenuItem>
                    <MenuItem value="POL_TERM_AVG">Monthly Average</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 1
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b6_key_benefit_1"
                    value={this.state.b6_key_benefit_1}
                    onChange={this.handleChange}
                    size="small"
                    name="b6_key_benefit_1"
                    label="Key Benefit 1"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 2
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b6_key_benefit_2"
                    value={this.state.b6_key_benefit_2}
                    onChange={this.handleChange}
                    size="small"
                    name="b6_key_benefit_2"
                    label="Key Benefit 2"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 3
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b6_key_benefit_3"
                    value={this.state.b6_key_benefit_3}
                    onChange={this.handleChange}
                    size="small"
                    name="b6_key_benefit_3"
                    label="Key Benefit 3"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir Before Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b6_max_benefit"
                    value={this.state.b6_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b6_max_benefit"
                    label="Nilai Bulan Terakhir Before Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir After Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b6_af_max_benefit"
                    value={this.state.b6_af_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b6_af_max_benefit"
                    label="Nilai Bulan Terakhir After Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Month 0 Benefit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b6_mth_0_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b6_mth_0_benefit"
                    label="Month 0 Benefit"
                  >
                    <MenuItem value="0">Month Benefit dimulai bulan 0</MenuItem>
                    <MenuItem value="1">Month Benefit dimulai bulan 1</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                ></br>
                <Divider
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="b7_logic"
                      value={this.state.b7_logic}
                      checked={!!this.state.b7_logic}
                      onChange={this.handleChange}
                    />
                  }
                  label="MAPPING BENEFIT 7"
                />
                <br
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <TextField
                    id="b7_jenis_manfaat"
                    name="b7_jenis_manfaat"
                    type="text"
                    label="Jenis Manfaat"
                    size="small"
                    variant="outlined"
                    value={this.state.b7_jenis_manfaat}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b7_basis"
                    value={this.state.b7_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b7_basis"
                    label="Basis"
                  >
                    <MenuItem value="1">Per 1000 UP</MenuItem>
                    <MenuItem value="2">Per 100 Premi</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Age Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b7_age_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b7_age_basis"
                    label="Age Basis"
                  >
                    <MenuItem value="POL_TERM_M">Monthly per Policy</MenuItem>
                    <MenuItem value="POL_TERM_AVG">Monthly Average</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 1
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b7_key_benefit_1"
                    value={this.state.b7_key_benefit_1}
                    onChange={this.handleChange}
                    size="small"
                    name="b7_key_benefit_1"
                    label="Key Benefit 1"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 2
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b7_key_benefit_2"
                    value={this.state.b7_key_benefit_2}
                    onChange={this.handleChange}
                    size="small"
                    name="b7_key_benefit_2"
                    label="Key Benefit 2"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 3
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b7_key_benefit_3"
                    value={this.state.b7_key_benefit_3}
                    onChange={this.handleChange}
                    size="small"
                    name="b7_key_benefit_3"
                    label="Key Benefit 3"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir Before Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b7_max_benefit"
                    value={this.state.b7_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b7_max_benefit"
                    label="Nilai Bulan Terakhir Before Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir After Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b7_af_max_benefit"
                    value={this.state.b7_af_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b7_af_max_benefit"
                    label="Nilai Bulan Terakhir After Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Month 0 Benefit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b7_mth_0_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b7_mth_0_benefit"
                    label="Month 0 Benefit"
                  >
                    <MenuItem value="0">Month Benefit dimulai bulan 0</MenuItem>
                    <MenuItem value="1">Month Benefit dimulai bulan 1</MenuItem>
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleSubmit}
                >
                  {this.state.btnWording}
                </Button>{" "}
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => {
                    window.history.back(-1);
                  }}
                >
                  Batal
                </Button>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  }
}

export default withStyles(styles)(ProsesUploadApp);
